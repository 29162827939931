<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center">
        <icon
            :data="icons.client"
            height="20"
            width="20"
            class="mr-2 cursor-pointer"
            original
        />
        <div>{{ item.fullname }}</div>
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        {{ item.phone }}
      </div>

      <div class="w-1/5 ml-4">
        {{ item.email }}
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        {{ item.entreprise_name }}
      </div>

      <div class="w-1/4 ml-4 flex items-center">
        {{ item.adresse }}
      </div>

    </div>
  </div>
</template>

<script>
import client from '../../../assets/icons/ic-clientAv.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        client,
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
